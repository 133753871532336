<template>
    <div>
        <Header/>
        <div class="grace-bodys">
            <p style="text-align: center;margin-top: 30px;font-size: 18px;color:#fff;">{{no_content}}</p>
        </div>
    </div>
</template>

<script>
import Header from "../Header";
export default {
  components:{Header},
  data(){
    return {
        no_content:'',
    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
      this.no_content = this.$zh_ft('no_content');
    }else{
      this.no_content = this.$zh_jt('no_content');
    }
  }
}
</script>
<style>
.grace-bodys{
    padding: 20px;
    width: unset;
    height: 90vh;
    background: #c49b6c;
    margin-top: -1rem;
}
</style>